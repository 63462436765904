<template>
    <content-wrapper>
        <w-data-table
            outlined
            :loading="loading"
            :headers="headers"
            :items="collection.data"
            :items-per-page="collection.per_page"
            :server-items-length="collection.total"
            :page="collection.current_page"
            searchable
            @change:options="fetchItems"
        >
            <template #filters>
                <div>
                    <v-select
                        v-model="filterByLevel"
                        :items="levels"
                        item-text="title"
                        item-value="uuid"
                        :placeholder="$trans('Level')"
                        dense
                        hide-details
                        clearable
                    >
                        <template #prepend>
                            <w-icon dense value="LEVEL" />
                        </template>
                    </v-select>
                </div>
                <div class="ml-md-4">
                    <v-select
                        :disabled="!filterByLevel"
                        v-model="filterByGroup"
                        :items="groups"
                        item-text="title"
                        item-value="uuid"
                        :placeholder="$trans('Group')"
                        dense
                        hide-details
                        clearable
                    >
                        <template #prepend>
                            <w-icon dense value="GROUP" />
                        </template>
                    </v-select>
                </div>
            </template>

            <template #actions>
                <w-btn-action
                    :text="$trans('Add student')"
                    icon="ADD"
                    @click="onShowForm"
                />
            </template>

            <template #[`item.last_name`]="{item}">
                <member-full-name
                    :item="item"
                />
            </template>

            <template #[`item.group`]="{item}">
                {{ item.group.title }}
            </template>

            <template #[`item.level`]="{item}">
                {{ item.group.level.title }}
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{item}">
                <v-btn
                    icon
                    color="success"
                    @click="onShowForm(item)"
                >
                    <w-icon value="EDIT" />
                </v-btn>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isAsideDrawer"
            :title="asideTitle"
        >
            <div v-if="isFormItem" class="px-4 pt-3">
                <v-card
                    outlined
                    class="d-flex align-center px-3"
                >
                    <div class="d-flex justify-center">
                        <member-avatar-form
                            :member="item"
                            member-type="student"
                            :size="60"
                            rounded
                        />
                    </div>
                    <div>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-icon>
                                    <w-icon value="GROUP" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item | memberFullName }}</v-list-item-title>
                                    <v-list-item-title>{{ $trans('Group') }}: {{ item.group.title }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.group.level.title }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-card>
            </div>
            <div class="pa-4">
                <student-form
                    :key="formKey"
                    :item="item"
                    @submit="onSubmit"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import MemberFullName from '@/components/Member/MemberFullName'
import StudentForm from '@apps/school/components/Student/StudentForm'
import ContentWrapper from '@/components/ContentWrapper'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'
import hasLevelsPropertyMixin from '@apps/school/mixins/hasLevelsPropertyMixin'
import { getStudentsCollection } from '@apps/school/api/students'
import MemberAvatarForm from '@apps/auth/components/MemberAvatarForm'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
export default {
    name: 'StudentsManage',
    components: {
        MemberAvatarForm,
        ContentWrapper,
        StudentForm,
        MemberFullName
    },
    mixins: [
        hasCurrentSchoolPropertyMixin,
        hasLevelsPropertyMixin,
        hasMemberFullNameFilterMixin
    ],
    computed: {
        isFormItem() {
            return Object.keys(this.item).length > 0
        },
        groups() {
            if (!this.filterByLevel) {
                return []
            }

            return this.levels.find(o => o.uuid === this.filterByLevel).groups
        },
        asideTitle() {
            return Object.keys(this.item).length === 0
                ? this.$trans('Add student')
                : this.$trans('Update student')
        },
        headers() {
            return [
                {
                    text: this.$trans('Full name'),
                    value: 'last_name',
                },
                {
                    text: this.$trans('Email'),
                    value: 'email'
                },
                {
                    text: this.$trans('Phone'),
                    value: 'phone'
                },
                {
                    text: this.$trans('Level'),
                    value: 'level',
                    sortable: false
                },
                {
                    text: this.$trans('Group'),
                    value: 'group'
                },
                {
                    value: 'actions',
                    sortable: false,
                    align: 'right'
                }
            ]
        }
    },
    watch: {
        currentSchool(val, oldVal) {
            if(val && !oldVal) {
                this.fetchItems()
            }
        },
        filterByLevel() {
            this.filterByGroup = null
            this.fetchItems()
        },
        filterByGroup() {
            this.fetchItems()
        }
    },
    data() {
        return {
            isAsideDrawer: false,
            loading: false,
            collection: {
                data: []
            },
            filterByLevel: null,
            filterByGroup: null,
            item: {},
            formKey: 0
        }
    },
    methods: {
        fetchItems(payload) {
            if(!this.currentSchool) return

            this.loading = true

            payload = payload || {}
            payload.school = this.currentSchool.uuid
            payload.level = this.filterByLevel
            payload.group = this.filterByGroup
            payload.with = 'group.level'

            if (!payload.perPage) {
                payload.perPage = 25
            }

            getStudentsCollection(payload)
                .then(response => {
                    this.collection = response.data.data
                    this.loading = false
                })
        },
        onShowForm(item) {
            this.item = item || {}
            this.isAsideDrawer = true
            this.formKey++
        },
        onSubmit(data) {
            this.item = {}
            this.isAsideDrawer = false
            this.loading = false
            this.fetchItems()
        }
    }
}
</script>

<style lang="scss">

</style>
