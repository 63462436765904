var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-wrapper',[_c('w-data-table',{attrs:{"outlined":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.collection.data,"items-per-page":_vm.collection.per_page,"server-items-length":_vm.collection.total,"page":_vm.collection.current_page,"searchable":""},on:{"change:options":_vm.fetchItems},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',[_c('v-select',{attrs:{"items":_vm.levels,"item-text":"title","item-value":"uuid","placeholder":_vm.$trans('Level'),"dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('w-icon',{attrs:{"dense":"","value":"LEVEL"}})]},proxy:true}]),model:{value:(_vm.filterByLevel),callback:function ($$v) {_vm.filterByLevel=$$v},expression:"filterByLevel"}})],1),_c('div',{staticClass:"ml-md-4"},[_c('v-select',{attrs:{"disabled":!_vm.filterByLevel,"items":_vm.groups,"item-text":"title","item-value":"uuid","placeholder":_vm.$trans('Group'),"dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('w-icon',{attrs:{"dense":"","value":"GROUP"}})]},proxy:true}]),model:{value:(_vm.filterByGroup),callback:function ($$v) {_vm.filterByGroup=$$v},expression:"filterByGroup"}})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('w-btn-action',{attrs:{"text":_vm.$trans('Add student'),"icon":"ADD"},on:{"click":_vm.onShowForm}})]},proxy:true},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('member-full-name',{attrs:{"item":item}})]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.group.title)+" ")]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.group.level.title)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.onShowForm(item)}}},[_c('w-icon',{attrs:{"value":"EDIT"}})],1)]}}],null,true)}),_c('w-aside-drawer',{attrs:{"title":_vm.asideTitle},model:{value:(_vm.isAsideDrawer),callback:function ($$v) {_vm.isAsideDrawer=$$v},expression:"isAsideDrawer"}},[(_vm.isFormItem)?_c('div',{staticClass:"px-4 pt-3"},[_c('v-card',{staticClass:"d-flex align-center px-3",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex justify-center"},[_c('member-avatar-form',{attrs:{"member":_vm.item,"member-type":"student","size":60,"rounded":""}})],1),_c('div',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('w-icon',{attrs:{"value":"GROUP"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("memberFullName")(_vm.item)))]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$trans('Group'))+": "+_vm._s(_vm.item.group.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.item.group.level.title))])],1)],1)],1)],1)])],1):_vm._e(),_c('div',{staticClass:"pa-4"},[_c('student-form',{key:_vm.formKey,attrs:{"item":_vm.item},on:{"submit":_vm.onSubmit}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }